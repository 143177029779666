import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getThemeData } from '../../store/selectors/themeSelector';
import './surveyScreen.css';
import { motion } from 'framer-motion';
import AlertComponent from './alert/alert';
import { browserHistory } from '../../index';
import MotionWrapper from '../motionWrapper';
import RatingCard from './ratingCard/ratingCard';
import RatingEnum from '../../helpers/ratingEnum';
import * as actions from '../../store/actions/rootActions';
import { useTranslation } from 'react-i18next';
import RandomHashGenerator from '../../helpers/randomHashGenerator';
import PropTypes from 'prop-types';

const buttonVariants = {
  hidden: {
    opacity: 0,
    y: '100vh',
    overflow: 'hidden',
    position: 'fixed',
    height: 0,
    bottom: 0,
    width: '100%',
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.2 },
    height: 'auto',
    overflow: 'visible',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  exit: {
    y: '100vh',
    transition: { ease: 'easeInOut' },
    height: 0,
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
};

const imageToBeDeleted = {
  questionId: null,
  index: -1,
};

const SurveyScreen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const surveyRedux = useSelector((state) => state.survey.survey);
  const isOnline = useSelector((state) => state.connectivity.isOnline);
  const [questions, setQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(-1);
  const [location, setLocation] = useState('');
  const [survey, setSurvey] = useState({
    survey_id: null,
    questions: [],
    suggestion: '',
  });
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const themeData = useSelector(getThemeData);
  const isConnected = useRef(true);

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const android = /android/i.test(navigator.userAgent);

  useEffect(() => {
    isConnected.current = isOnline;
  }, [isOnline]);

  useEffect(() => {
    if (android) {
      const initialScreenSize = window.innerHeight;
      window.addEventListener('resize', () =>
        onResizeAndroid(initialScreenSize)
      );
      return () => {
        window.removeEventListener('resize', () =>
          onResizeAndroid(initialScreenSize)
        );
      };
    }
    if (iOS && 'visualViewport' in window) {
      visualViewport.addEventListener('resize', onResizeIOS);

      return () => {
        visualViewport.removeEventListener('resize', onResizeIOS);
      };
    }
  }, [android, iOS]);

  const onResizeIOS = (event) => {
    const relativeDifferenceBetweenInnerHeightAndViewportHeight =
      (window.innerHeight - event.target.height) / window.innerHeight;

    relativeDifferenceBetweenInnerHeightAndViewportHeight > 0.1
      ? setIsKeyboardOpen(true)
      : setIsKeyboardOpen(false);
  };

  const onResizeAndroid = (initialScreenSize) => {
    if (initialScreenSize > window.innerHeight) {
      setIsKeyboardOpen(true);
    } else {
      setIsKeyboardOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      document.activeElement.blur();
    }
  };

  const [onDismiss, setOnDismiss] = useState(() => () => {});
  const [onSuccess, setOnSuccess] = useState(() => () => {});

  useEffect(() => {
    if (surveyRedux) {
      setLocation(surveyRedux.area_name);
      setQuestions(
        surveyRedux.questions.map((question) => ({
          ...question,
        }))
      );
      setSurvey({
        ...survey,
        questions: surveyRedux.questions.map((question) => ({
          ...question,
        })),
      });
    } else {
      browserHistory.push('/error');
    }
    // eslint-disable-next-line
  }, []);

  const fileToUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });
  };

  const convertFiles = async (event) => {
    const updatedImages = [];
    for (let i = 0; i < event.target.files.length; i++) {
      const image = {
        imageUrl: '',
        imageFile: event.target.files[i],
      };
      image.imageUrl = await fileToUrl(event.target.files[i]);
      updatedImages.push(image);
    }
    return updatedImages;
  };

  const onAddComment = (comment, id) => {
    const updatedQuestions = questions.map((question) => {
      if (question.question_id === id) {
        question.comment = comment;
      }
      return question;
    });
    setQuestions(updatedQuestions);
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onAddPhotos = (photos, id) => {
    const updatedQuestions = questions.map((question) => {
      if (question.question_id === id) {
        question.photos = photos;
      }
      return question;
    });
    setQuestions(updatedQuestions);
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onRating = (rating, id) => {
    const updatedQuestions = questions.map((question) => {
      if (question.question_id === id) {
        question.ranking = RatingEnum[rating];
      }
      return question;
    });
    setQuestions(updatedQuestions);
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onAddSuggestion = (event) => {
    setSurvey({ ...survey, suggestion: event.target.value });
  };

  const getBase4Photo = async (file) => {
    return await toBase64(file);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = async () => {
    document.activeElement.blur();
    if (isConnected.current) {
      setShowButtons(false);
      const surveyValue = { ...survey, reviewHash: '' };
      surveyValue.reviewHash = RandomHashGenerator();
      surveyValue.questions = surveyValue.questions.filter(
        (question) => question.ranking !== ''
      );
      if (surveyValue.questions.length >= 1) {
        let surveyPhotos = [];
        const questionsWithPhotos = surveyValue.questions.filter(
          (question) => question.photos.length > 0
        );
        for (const question of questionsWithPhotos) {
          const photos = [];
          for (const photo of question.photos) {
            const base64Photo = await getBase4Photo(photo.imageFile);
            photos.push({
              id: RandomHashGenerator(Math.floor(Math.random() * 10) + 4),
              question_id: question.question_id,
              photo: base64Photo,
              imageHash: RandomHashGenerator(),
            });
          }
          surveyPhotos = surveyPhotos.concat(photos);
          question.photos = [];
        }
        dispatch(actions.saveReview(surveyValue));
        dispatch(actions.savePendingPhotos([]));
        props.history.push({
          pathname: '/upload',
          state: {
            photos: surveyPhotos,
          },
        });
      } else {
        setAlertMessage(t('alert_rank_validation'));
      }
    }
  };

  const onRemoveImage = (questionId, index) => {
    setOnDismiss(() => () => {
      imageToBeDeleted.questionId = null;
      imageToBeDeleted.index = -1;
      setAlertMessage(null);
    });
    setOnSuccess(() => () => {
      removeImageHandler(imageToBeDeleted.questionId, imageToBeDeleted.index);
      setAlertMessage(null);
    });
    setShowButtons(true);
    imageToBeDeleted.questionId = questionId;
    imageToBeDeleted.index = index;
    setAlertMessage(t('alert_delete_image'));
  };

  const removeImageHandler = (questionId, index) => {
    const question = survey.questions.find(
      (question) => question.question_id === questionId
    );
    const photos = [...question.photos];
    photos.splice(index, 1);
    onAddPhotos(photos, questionId);
    imageToBeDeleted.questionId = null;
    imageToBeDeleted.index = -1;
  };
  return (
    <>
      {alertMessage && (
        <AlertComponent
          alertMessage={alertMessage}
          onSuccess={onSuccess}
          onCancel={onDismiss}
          showButtons={showButtons}
        />
      )}
      <div className="survey-wrapper">
        {questions.length > 0 && (
          <>
            <div className="container p-bottom">
              <MotionWrapper>
                <div className="row">
                  <div className="col-12 text-center">
                    <h3>{location}</h3>
                  </div>
                </div>
                {questions.map((question) => {
                  return (
                    <RatingCard
                      key={question.question_id}
                      id={question.question_id}
                      name={question.name}
                      deatilsButtonText={
                        themeData.questionsScreen.details.button.text
                      }
                      images={question.photos}
                      activeQuestion={activeQuestion}
                      setActiveQuestion={setActiveQuestion}
                      onAddComment={onAddComment}
                      onAddPhotos={onAddPhotos}
                      onRating={onRating}
                      onRemoveImage={onRemoveImage}
                      convertFiles={convertFiles}
                    />
                  );
                })}
                <div className="row m-top-30">
                  <div className="col-12 suggestion-box">
                    <h5>{themeData.questionsScreen.suggestion.title.text}</h5>
                    <textarea
                      placeholder={
                        themeData.questionsScreen.suggestion.placeholder
                      }
                      maxLength="2000"
                      className="form-control"
                      id=""
                      rows="3"
                      onKeyDown={handleKeyDown}
                      onChange={onAddSuggestion}
                      onFocus={() => setActiveQuestion(-1)}
                    ></textarea>
                  </div>
                </div>
              </MotionWrapper>
            </div>

            {!isKeyboardOpen && (
              <motion.div
                className="sb"
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <div className="submit-bottom">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      onClick={onSubmit}
                    >
                      {t('submit_button')}
                    </button>
                  </div>
                </div>
              </motion.div>
            )}
          </>
        )}
      </div>
    </>
  );
};

SurveyScreen.defaultProps = {
  history: { push: () => {} },
};

SurveyScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SurveyScreen;
