export default class ConcreteTheme {
  constructor(
    primaryColor,
    secondaryColor,
    welcomeScreenTitle,
    questionsScreenSuggestionTitleText,
    questionsScreenDetailsButtonText,
    questionsScreenSuggestionPlaceholder,
    followUpScreenIcon,
    followUpScreenSecondTitleText,
    followUpScreenFollowUpButtonText,
    uploadScreenGif,
    splashScreenIcon,
    favIcon,
    followupPart1Text,
    followupPart2Text
  ) {
    this.initialize();

    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;

    this.welcomeScreen.title.text = welcomeScreenTitle;

    this.questionsScreen.suggestion.title.text =
      questionsScreenSuggestionTitleText;
    this.questionsScreen.details.button.text = questionsScreenDetailsButtonText;
    this.questionsScreen.suggestion.placeholder =
      questionsScreenSuggestionPlaceholder;

    this.followUpScreen.icon = followUpScreenIcon;
    this.followUpScreen.secondTitle.text = followUpScreenSecondTitleText;
    this.followUpScreen.followUpButton.text = followUpScreenFollowUpButtonText;
    this.followUpScreen.redirect.part1Text = followupPart1Text;
    this.followUpScreen.redirect.part2Text = followupPart2Text;

    this.uploadScreen.gif = uploadScreenGif;
    this.splashScreen.icon = splashScreenIcon;
    this.favicon = favIcon;
  }

  initialize() {
    this.primaryColor = '';
    this.secondaryColor = '';

    this.welcomeScreen = {
      title: {
        text: '',
      },
    };

    this.questionsScreen = {
      details: {
        button: {
          text: '',
        },
        placeholder: '',
      },
      suggestion: {
        title: {
          text: '',
        },
        placeholder: '',
      },
    };

    this.followUpScreen = {
      icon: '',
      secondTitle: {
        text: '',
      },
      followUpButton: {
        text: '',
      },
      redirect: {
        part1Text: '',
        part2Text: '',
      },
    };

    this.uploadScreen = { gif: '' };
    this.splashScreen = { icon: '' };
    this.favicon = '';
  }
}
