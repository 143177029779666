import ConcreteTheme from './concreteTheme';
import {
  MY_TEAM_INSIGHTS_THEME_THANKS_IMAGE,
  MY_TEAM_INSIGHTS_THEME_UPLOADING_GIF,
  MY_TEAM_INSIGHTS_THEME_LOGO,
  MY_TEAM_INSIGHTS_THEME_FAV_ICON,
} from '../helpers/assetsConstants';
import colors from '../helpers/colors';
import i18next from '../react-i18next';

const { myTeamInsightsThemePrimaryColor, myTeamInsightsThemeSecundaryColor } =
  colors;

const theme = () =>
  new ConcreteTheme(
    myTeamInsightsThemePrimaryColor,
    myTeamInsightsThemeSecundaryColor,
    i18next.t('my_team_insights_theme_welcome_title'),
    i18next.t('my_team_insights_theme_suggestion_title'),
    i18next.t('my_team_insights_theme_add_details_button'),
    i18next.t('my_team_insights_theme_add_general_comment_placeholder'),
    MY_TEAM_INSIGHTS_THEME_THANKS_IMAGE,
    i18next.t('my_team_insights_theme_thanks_part2'),
    i18next.t('my_team_insights_theme_follow_up_button'),
    MY_TEAM_INSIGHTS_THEME_UPLOADING_GIF,
    MY_TEAM_INSIGHTS_THEME_LOGO,
    MY_TEAM_INSIGHTS_THEME_FAV_ICON,
    i18next.t('my_team_insights_theme_followup_part1'),
    i18next.t('my_team_insights_theme_followup_part2')
  );

export default theme;
