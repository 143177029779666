const DEFAULT_PRIMARY_COLOR = '#95d2d0';
const DEFAULT_SECUNDARY_COLOR = '#858796';

const colors = {
  myCustomerVoiceThemePrimaryColor: DEFAULT_PRIMARY_COLOR,
  myCustomerVoiceThemeSecundaryColor: DEFAULT_SECUNDARY_COLOR,
  myTeamInsightsThemePrimaryColor: DEFAULT_PRIMARY_COLOR,
  myTeamInsightsThemeSecundaryColor: DEFAULT_SECUNDARY_COLOR,
  thirdColor: '#f6c23e',
  backgroundColor: '#000',
};

export default colors;
