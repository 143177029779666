const THEMES_TYPE = {
  myCustomerVoice: 'MY_CUSTOMER_VOICE',
  myTeamInsights: 'MY_TEAM_INSIGHTS',
};

const THANKS_INPUT_TYPE = {
  email: 'email',
  name: 'name',
  phone: 'phone',
};

export { THEMES_TYPE, THANKS_INPUT_TYPE };
