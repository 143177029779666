import ConcreteTheme from './concreteTheme';
import {
  MY_CUSTOMER_VOICE_THEME_THANKS_IMAGE,
  MY_CUSTOMER_VOICE_THEME_UPLOADING_GIF,
  MY_CUSTOMER_VOICE_THEME_LOGO,
  MY_CUSTOMER_VOICE_THEME_FAV_ICON,
} from '../helpers/assetsConstants';
import colors from '../helpers/colors';
import i18next from '../react-i18next';

const { myCustomerVoiceThemePrimaryColor, myCustomerVoiceThemeSecundaryColor } =
  colors;

const theme = () =>
  new ConcreteTheme(
    myCustomerVoiceThemePrimaryColor,
    myCustomerVoiceThemeSecundaryColor,
    i18next.t('my_customer_voice_theme_welcome_title'),
    i18next.t('my_customer_voice_theme_suggestion_title'),
    i18next.t('my_customer_voice_theme_add_details_button'),
    i18next.t('my_customer_voice_theme_add_general_comment_placeholder'),
    MY_CUSTOMER_VOICE_THEME_THANKS_IMAGE,
    i18next.t('my_customer_voice_theme_thanks_part2'),
    i18next.t('my_customer_voice_theme_follow_up_button'),
    MY_CUSTOMER_VOICE_THEME_UPLOADING_GIF,
    MY_CUSTOMER_VOICE_THEME_LOGO,
    MY_CUSTOMER_VOICE_THEME_FAV_ICON,
    i18next.t('my_customer_voice_theme_followup_part1'),
    i18next.t('my_customer_voice_theme_followup_part2')
  );

export default theme;
